<template>
    <div class="row text-xs-left" ref="clipboard">
        <div class="xs12">
            <div class="row font-16 font-bold">Thông tin vé</div>

            <div
                class="pl-2 row align-center pb-1 pt-2"
                style="border-bottom: 1px solid #dadce0"
            >
                <div class="xs4 mr-3 font-16">Giá vé</div>
                <div
                    class="xs8 text-xs-right font-16 font-medium break-word color-success"
                >
                    {{ $Helper.Common.moneyFormat(item.giaVe) }} đồng
                </div>
            </div>
            <div
                class="pl-2 row align-center pb-1 pt-2"
                style="border-bottom: 1px solid #dadce0"
            >
                <div class="xs4 mr-3 font-16">Thời gian mua vé</div>
                <div class="xs8 text-xs-right font-16 font-medium break-word">
                    {{ $Helper.Common.formatDateTime(item.thoiGianMuaVe) }}
                </div>
            </div>
            <div
                class="pl-2 row align-center pb-1 pt-2"
                style="border-bottom: 1px solid #dadce0"
            >
                <div class="xs4 mr-3 font-16">
                    Mã tra cứu tại đơn vị giải pháp hóa đơn
                </div>
                <div class="xs8 text-xs-right font-16 font-medium break-word">
                    {{ item.maTraCuu }}
                    <DxButton
                        type="default"
                        styling-mode="text"
                        hint="Sao chép"
                        icon="mdi mdi-content-copy"
                        @click="SaoChep(item.maTraCuu)"
                    />
                </div>
            </div>
            <div
                class="pl-2 row align-center pb-1 pt-2"
                style="border-bottom: 1px solid #dadce0; white-space: break-spaces"
            >
                <div class="xs4 mr-3 font-16">
                    {{ `Link tra cứu của đơn vị giải pháp hóa đơn` }}
                </div>
                <div
                    class="xs8 text-xs-right font-16 font-medium"
                    style="word-break: break-all"
                >
                    <a :href="item.linkRedirect || item.linkTraCuuHDDT" target="_blank">
                        {{ item.linkTraCuuHDDT }}
                    </a>
                </div>
            </div>
            <div
                class="pl-2 row align-center pb-1 pt-2"
                style="border-bottom: 1px solid #dadce0"
            >
                <div class="xs4 mr-3 font-16">Trạng thái vé</div>
                <div
                    class="xs8 text-xs-right font-16 font-medium break-word"
                    :style="`color: ${item.maMauTrangThai}`"
                >
                    {{ item.tenTrangThai }}
                </div>
            </div>

            <div class="row font-16 font-bold mt-3">Thông tin chuyến đi</div>

            <div
                class="pl-2 row align-center pb-1 pt-2"
                style="border-bottom: 1px solid #dadce0"
            >
                <div class="xs4 mr-3 font-16">Mã chuyến đi</div>
                <div class="xs8 text-xs-right font-16 font-medium break-word">
                    {{ item.maChuyenDi }}
                    <DxButton
                        type="default"
                        styling-mode="text"
                        hint="Sao chép"
                        icon="mdi mdi-content-copy"
                        v-if="item.maChuyenDi"
                        @click="SaoChep(item.maChuyenDi)"
                    />
                </div>
            </div>
            <div
                class="pl-2 row align-center pb-1 pt-2"
                style="border-bottom: 1px solid #dadce0"
            >
                <div class="xs4 mr-3 font-16">Biển số xe</div>
                <div class="xs8 text-xs-right font-16 font-medium break-word">
                    {{ item.bienKiemSoat }}
                </div>
            </div>
            <div
                class="pl-2 row align-center pb-1 pt-2"
                style="border-bottom: 1px solid #dadce0"
            >
                <div class="xs4 mr-3 font-16">Tuyến vận chuyển</div>
                <div class="xs8 text-xs-right font-16 font-medium break-word">
                    {{
                        `${item.tuyenVanChuyen}${
                            item.maLuongTuyen ? ` (${item.maLuongTuyen})` : ``
                        }`
                    }}
                </div>
            </div>
            <div
                class="pl-2 row align-center pb-1 pt-2"
                style="border-bottom: 1px solid #dadce0"
                v-if="item.tenDiemKetThuc"
            >
                <div class="xs4 mr-3 font-16">Tuyến vận chuyển</div>
                <div class="xs8 text-xs-right font-16 font-medium break-word">
                    {{ `${item.tenDiemKetThuc}` }}
                </div>
            </div>
            <div
                class="pl-2 row align-center pb-1 pt-2"
                style="border-bottom: 1px solid #dadce0"
            >
                <div class="xs4 mr-3 font-16">Thời gian khởi hành</div>
                <div class="xs8 text-xs-right font-16 font-medium break-word">
                    {{ $Helper.Common.formatDateTime(item.thoiGianKhoiHanh) }}
                </div>
            </div>

            <div class="row font-16 font-bold mt-3">Thông tin hành khách</div>
            <div
                class="pl-2 row align-center pb-1 pt-2"
                style="border-bottom: 1px solid #dadce0"
            >
                <div class="xs4 mr-3 font-16">Số điện thoại</div>
                <div class="xs8 text-xs-right font-16 font-medium break-word">
                    {{ item.soDienThoaiHanhKhach }}
                </div>
            </div>
            <div
                class="pl-2 row align-center pb-1 pt-2"
                style="border-bottom: 1px solid #dadce0"
            >
                <div class="xs4 mr-3 font-16">Họ tên</div>
                <div class="xs8 text-xs-right font-16 font-medium break-word">
                    {{ item.hoTenHanhKhach }}
                </div>
            </div>
            <div
                class="pl-2 row align-center pb-1 pt-2"
                style="border-bottom: 1px solid #dadce0"
            >
                <div class="xs4 mr-3 font-16">Email</div>
                <div class="xs8 text-xs-right font-16 font-medium break-word">
                    {{ item.emailHanhKhach }}
                </div>
            </div>
            <div class="row font-16 font-bold mt-3">Thông tin doanh nghiệp</div>
            <div
                class="pl-2 row align-center pb-1 pt-2"
                style="border-bottom: 1px solid #dadce0"
            >
                <div class="xs4 mr-3 font-16">Tên doanh nghiệp</div>
                <div class="xs8 text-xs-right font-16 font-medium break-word">
                    {{ item.tenDoanhNghiep }}
                </div>
            </div>
            <div
                class="pl-2 row align-center pb-1 pt-2"
                style="border-bottom: 1px solid #dadce0"
            >
                <div class="xs4 mr-3 font-16">Mã số thuế doanh nghiệp</div>
                <div class="xs8 text-xs-right font-16 font-medium break-word">
                    {{ item.maSoThueDoanhNghiep }}
                    <DxButton
                        type="default"
                        styling-mode="text"
                        hint="Sao chép"
                        icon="mdi mdi-content-copy"
                        v-if="item.maSoThueDoanhNghiep"
                        @click="SaoChep(item.maSoThueDoanhNghiep)"
                    />
                </div>
            </div>
            <div
                class="pl-2 row align-center pb-1 pt-2"
                style="border-bottom: 1px solid #dadce0"
            >
                <div class="xs4 mr-3 font-16">Số điện thoại doanh nghiệp</div>
                <div class="xs8 text-xs-right font-16 font-medium break-word">
                    {{ $Helper.Common.formatPhone(item.soDienThoaiDoanhNghiep) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DxButton } from "devextreme-vue/button";
export default {
    components: {
        DxButton,
    },
    props: {
        item: { type: Object, default: () => {} },
    },
    methods: {
        ThongBaoToast(type, msg) {
            let modelToast = {
                isVisible: true,
                message: msg,
                type: type,
            };
            this.emitter.emit("onToast", modelToast);
        },
        async SaoChep(text) {
            try {
                await this.$toClipboard(text);
                this.ThongBaoToast("success", `Sao chép ${text} thành công!`);
            } catch (error) {
                console.log("🚀 ~ error", error);
                this.ThongBaoToast("error", "Lấy thông tin vé thất bại! Lỗi hệ thống");
            }
        },
    },
};
</script>

<style scoped>
.break-word {
    word-wrap: break-word;
}
</style>
